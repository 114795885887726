.test-active {
  border: 1px solid #fff !important;
}

.test-active2 {
  border-left: 2px solid #fff;
  background: rgba(255, 255, 255, 0.2);
}

.plus-icon {
  font-size: 20px;
  border-radius: 2px;
  margin-bottom: 8px;
  margin-top: 8px;
  color: #fff;
  cursor: pointer;
  width: 35px;
  height: 35px;
  /* border: 1px solid gray; */
}
