.bg-image {
  background-image: url(../../../../src/images/lightblue-bg-img-login.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
}
.login-btn {
  background-color: #506bec !important;
}

.login-btn:hover {
  background-color: #6080ff !important;
}

.login-text {
  color: #506bec !important;
}

.login-text:hover {
  color: #6080ff !important;
}
