.space-above-underline {
  text-decoration-line: underline;
  text-decoration-thickness: 2px;
  text-underline-offset: 12px;
}

.grey-text {
  color: #b5b5c3;
}

@media (min-width: 1200px) {
  .custom-modal {
    min-width: 900px;
  }
}

.custom-modal-content {
  /* overflow-y: auto; */
  max-height: 90vh;
}

.zoom-effect {
  transform: scale(1);
}

.zoom-effect:hover {
  transform: scale(1.02);
}
