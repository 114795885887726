/* KanbanBoardDemo.css */
.kt-portlet {
  border: 1px solid #ebedf2;
  border-radius: 4px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.kt-portlet__head {
  border-bottom: 1px solid #ebedf2;
  padding: 15px;
}

.kt-portlet__head-title {
  font-size: 1.2rem;
  margin: 0;
}

.kanban-board-list {
  display: flex;
  flex-direction: row;
  /* gap: 10px; */
  overflow-x: auto;
  padding: 10px;
}

.kanban-board {
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  min-height: 200px;
}

.kanban-board-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.kanban-title-board {
  font-size: 1.1rem;
  width: 90%;
}

.kanban-drag {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
}

.kanban-item {
  /* background: #f8f9fa; */
  /* background: transparent; */
  border-radius: 4px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  padding: 10px;
  border-left: 3px solid #0d98ba;
}

.kanban-item-content {
  word-break: break-word;
}

/* Button Styling */
.btn {
  display: inline-block;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.2;
  text-align: center;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border: 1px solid #dc3545;
}

.btn-danger:hover {
  background-color: #c82333;
  border-color: #bd2130;
}

.switch {
  position: relative;
  display: inline-block;
  width: 26px;
  height: 15px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 8px;
  width: 8px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(10px);
  -ms-transform: translateX(10px);
  transform: translateX(10px);
}

.slider.round {
  border-radius: 10px;
}

.slider.round:before {
  border-radius: 50%;
}

.blinking-text-selector {
  display: inline-block;
  width: 1px;
  height: 1.2em;
  background-color: black;
  animation: blink-caret 1s step-end infinite;
}

@keyframes blink-caret {
  from,
  to {
    background-color: transparent;
  }
  50% {
    background-color: black;
  }
}

/* .kanban-item {
  position: relative;
  padding: 10px;
  margin-bottom: 10px;
  background-color: white;
  border: 1px solid #dee2e6;
  border-radius: 4px;
}

.kanban-item-content {
  padding: 5px 0;
} */

.template_name {
  width: 100%;
  margin-bottom: 10px;
}
.add_section_btn {
  width: 100%;
  margin-bottom: 10px;
}

@media (min-width: 992px) {
  .template_name {
    width: 30%;
    margin-bottom: 0px;
  }
  .add_section_btn {
    width: 10%;
    margin-bottom: 0px;
  }
}
