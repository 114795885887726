.Toastify__toast--success {
  background-color: var(--bs-success) !important;
}

.Toastify__toast-body {
  font-family: DM Sans, Helvetica, 'sans-serif';
  font-size: '14px' !important;
  font-weight: 400;
}

.light-mode .react-tel-input {
  width: auto;
  border: 1px solid #dbdfe9;
  padding: 3px;
  border-radius: 8px;
  background-color: #fff;
}
.dark-mode .react-tel-input {
  width: auto;
  border: 1px solid #232348;
  padding: 3px;
  border-radius: 8px;
  background-color: #1e1e2d;
}

.react-tel-input .form-control {
  border: none;
  width: 100%; /* or any other width you prefer */
  color: #000;
}

.light-mode .react-tel-input .form-control {
  background-color: #fff;
}

/* Apply styles to .react-tel-input when dark mode is active */
.dark-mode .react-tel-input .form-control {
  background-color: #1e1e2d;
  color: #92929f;
}

/* Dark Mode Styles for .flag-dropdown */
.dark-mode .react-tel-input .flag-dropdown {
  border: none;
  background-color: #1e1e2d;
}

/* Dark Mode Hover Effect */
.dark-mode .react-tel-input .flag-dropdown:hover {
  background-color: #2a2a3b; /* Example hover background color for dark mode */
}

/* Light Mode Styles for .flag-dropdown */
.light-mode .react-tel-input .flag-dropdown {
  border: none;
  background-color: #fff;
}

/* Light Mode Hover Effect */
.light-mode .react-tel-input .flag-dropdown:hover {
  background-color: #f0f0f0; /* Example hover background color for light mode */
}
/* Light Mode Hover Effect */
.dark-mode .react-tel-input .flag-dropdown:hover {
  background-color: #1e1e2d; /* Example hover background color for light mode */
}

/* Dark Mode Styles for Country Dropdown */
.dark-mode .react-tel-input .flag-dropdown {
  background-color: #1e1e2d; /* Dark mode background color for dropdown */
}

/* Light Mode Styles for Country Dropdown */
.light-mode .react-tel-input .flag-dropdown {
  background-color: #fff; /* Light mode background color for dropdown */
}

/* Dark Mode Hover Effect for Dropdown Items */
.dark-mode .react-tel-input .flag-dropdown .country-list {
  background-color: #1e1e2d; /* Dark mode background color for dropdown items */
}

.dark-mode .react-tel-input .flag-dropdown .country-list .country {
  background-color: #1e1e2d; /* Dark mode background color for individual items */
}

.dark-mode .react-tel-input .flag-dropdown .country-list .country:hover {
  background-color: #2a2a3b; /* Dark mode hover background color for items */
}

/* Light Mode Hover Effect for Dropdown Items */
.light-mode .react-tel-input .flag-dropdown .country-list {
  background-color: #fff; /* Light mode background color for dropdown items */
}

.light-mode .react-tel-input .flag-dropdown .country {
  background-color: #fff; /* Light mode background color for individual items */
}

.light-mode .react-tel-input .flag-dropdown .country:hover {
  background-color: #f0f0f0; /* Light mode hover background color for items */
}

.dark-mode .react-tel-input .country-list .search {
  background-color: #1e1e2d;
}

.react-select-container.is-invalid .react-select__control {
  border-color: red; /* Example: border color for invalid state */
}

.react-select-container.is-valid .react-select__control {
  border-color: #50cd89; /* Example: border color for invalid state */
}

/* Solid form styles */
.form-solid-light-mode .react-tel-input .form-control {
  border-radius: 8px;
  background-color: #f9f9f9;
  height: 42px;
}
.form-solid-dark-mode .react-tel-input .form-control {
  border-radius: 8px;
  background-color: #1b1b29;
  height: 42px;
  color: #92929f;
}

.form-solid-dark-mode .react-tel-input .flag-dropdown {
  background-color: #1b1b29; /* Dark mode background color for dropdown */
  border: 1px solid #92929f;
}

.form-solid-dark-mode .react-tel-input .flag-dropdown {
  border: none;
  background-color: #1e1e2d;
}

/* Dark Mode Hover Effect */
/* .form-solid-dark-mode .react-tel-input .flag-dropdown:hover {
  background-color: #2a2a3b;
} */
.form-solid-dark-mode .react-tel-input .flag-dropdown .country-list {
  background-color: #1e1e2d; /* Dark mode background color for dropdown items */
}

.form-solid-dark-mode .react-tel-input .flag-dropdown .country-list .country {
  background-color: #1e1e2d; /* Dark mode background color for individual items */
}

.form-solid-dark-mode .react-tel-input .flag-dropdown .country-list .country:hover {
  background-color: #2a2a3b; /* Dark mode hover background color for items */
}

.form-solid-dark-mode .react-tel-input .country-list .search {
  background-color: #1e1e2d;
}
