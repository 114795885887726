.react-datepicker-fullwidth-wrapper > .react-datepicker-wrapper {
  width: 100% !important;
}

.dark-mode .react-datepicker,
.dark-mode .react-datepicker__header {
  background: #2c2f43;
  color: #ffffff;
  outline: 0px;
  border: 0px;
}

.dark-mode .react-datepicker__current-month,
.dark-mode .react-datepicker-time__header,
.dark-mode .react-datepicker__day-name,
.dark-mode .react-datepicker__day,
.dark-mode .react-datepicker__year-dropdown-container--select,
.dark-mode .react-datepicker__month-dropdown-container--select,
.dark-mode .react-datepicker__month-year-dropdown-container--select,
.dark-mode .react-datepicker__year-dropdown-container--scroll,
.dark-mode .react-datepicker__month-dropdown-container--scroll,
.dark-mode .react-datepicker__month-dropdown,
.dark-mode .react-datepicker__year-dropdown,
.dark-mode .react-datepicker-time__input,
.dark-mode .react-datepicker__day:hover,
.dark-mode .react-datepicker__year-text:hover,
.dark-mode .react-datepicker__month-year-dropdown-container--scroll {
  background-color: #2c2f43;
  color: #ffffff;
}
/* 
  .test .react-datepicker__year-dropdown--scroll,
  .test .react-datepicker__year-dropdown--scroll .react-datepicker__navigation {
    color: #000000;
  }
  
  .test .react-datepicker__navigation--years {
    color: black;
  } */

.dark-mode .react-datepicker__month-container .react-datepicker__day--disabled,
.dark-mode .react-datepicker__month-container .react-datepicker__day--disabled:hover {
  color: #999999;
}
