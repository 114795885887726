.bg-custom {
  /* background-color: rgba(var(--bs-secondary-rgb), 0.1) !important; */
  padding: 8px;
  border-radius: 2px;
  margin-bottom: 8px;
  margin-top: 8px;
  color: #fff;
  cursor: pointer;
  width: 35px;
  height: 35px;
  /* background-color: #fff; */
}

.test-active {
  background-color: rgba(var(--bs-secondary-rgb), 0.1) !important;
  border: 1px solid #fff !important;
}

.test-active2 {
  border-left: 2px solid #fff;
}

.test-active3 {
  /* box-shadow: -8px 0 8px -4px rgba(0, 0, 0, 0.2), -20px 0 20px -10px rgba(0, 0, 0, 0.19); */
  /* box-shadow: -20px 0 20px -10px rgba(0, 0, 0, 0.19); */
  box-shadow: -8px 0 8px -4px rgba(0, 0, 0, 0.2);
}

.border-orange {
  border: 1px solid orange;
}

.plus-icon {
  /* background-color: rgba(var(--bs-secondary-rgb), 0.1) !important; */
  /* padding: 8px; */
  font-size: 20px;
  border-radius: 2px;
  margin-bottom: 8px;
  margin-top: 8px;
  color: #fff;
  cursor: pointer;
  width: 35px;
  height: 35px;
  border: 1px solid gray;
}

.test-1::-webkit-scrollbar {
  display: none;
}

/* ScrollBar.css */

/* Track */

.test {
  overflow: auto;
}

.test::-webkit-scrollbar {
  width: 15px; /* Width of the entire scrollbar */
}

.test::-webkit-scrollbar-track {
  background: #fff;
  border: 2px solid red;
}

/* Handle */
.test:-webkit-scrollbar-thumb {
  background: linear-gradient(red, yellow);
  /* background: #888; */
  /* border-radius: 4px;  */
}

/* Handle on hover */
.test:-webkit-scrollbar-thumb:hover {
  /* background: #555; */
  background: linear-gradient(yellow, red);
}

.test-scrollbar {
  overflow: auto;
  scrollbar-width: 10px;
  scrollbar-color: blue;
}
