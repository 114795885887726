.timeline {
  padding: 20px;
}

.timeline-event {
  border-left: 2px solid #007bff;
  padding-left: 20px;
  margin-bottom: 20px;
}

.timeline-event-content {
  /* background: transparent; */
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.timeline-event-content h3 {
  margin-top: 0;
}

.timestamp {
  font-size: 12px;
  color: #888;
}

.designation-btn {
  min-width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding: 5px;
}
