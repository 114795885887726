.icon-size {
  font-size: 28px !important;
}

.bg-image {
  background-image: url(../../../images/lightblue-bg-img-login.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
}
